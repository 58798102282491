import React from 'react';
import Layout from '../components/layout/Layout';
import AdminUsersIndex from '../components/admin_users/Index';

export default (props) => {
  return (
    <Layout page="admin_users">
      <AdminUsersIndex />
    </Layout>
  );
};
