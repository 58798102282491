import React, { useEffect, useState } from 'react';
import {
  Button as MaterialButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'gatsby-theme-material-ui';
import moment from 'moment';
import * as API from '../../services/api';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),

    '& .MuiTypography-root': {
      flexGrow: 1,
    },
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),

    '& .MuiButtonBase-root': {
      minWidth: 30,
    },
  },
}));

const perPage = 20;

const formatDate = (date) => {
  return moment(date).format('MMM DD, YYYY hh:mm');
};

export default function AdminUserIndex(props) {
  const [adminUsers, setAdminUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [adminUsersTotal, setAdminUsersTotal] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    if (!adminUsers.length) {
      API.getAllAdminUsers(page, perPage).then((data) => {
        if (data) {
          setAdminUsers(data.adminUsers);
          setAdminUsersTotal(data.total);
        }
      });
    }
  }, [adminUsers, page]);

  if (!adminUsers.length) {
    return <CircularProgress />;
  }

  const pageCount = Math.ceil(adminUsersTotal / perPage);
  const gotoPage = (nextPage) => {
    setAdminUsers([]);
    setPage(nextPage);
  };

  const deleteAdminUser = async (uuid) => {
    if (window.confirm('Are you sure?')) {
      await API.deleteAdminUser(uuid);
      setAdminUsers([]);
    }
  };

  return (
    <>
      <Paper className={classes.nav}>
        <Typography variant="h6">Admin Users</Typography>
        <Button color="primary" to="/new_admin_user">
          New Admin User
        </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Internal ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminUsers &&
              adminUsers.map((adminUser) => (
                <TableRow key={adminUser.uuid}>
                  <TableCell>{adminUser.uuid}</TableCell>
                  <TableCell>{adminUser.email}</TableCell>
                  <TableCell>{formatDate(adminUser.createdAt)}</TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      size="small"
                      to={`/admin_users/${adminUser.uuid}`}
                    >
                      View
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      to={`/admin_users/${adminUser.uuid}/edit`}
                    >
                      Edit
                    </Button>
                    <MaterialButton
                      color="secondary"
                      size="small"
                      onClick={() => deleteAdminUser(adminUser.uuid)}
                    >
                      Delete
                    </MaterialButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCount > 1 && (
        <Paper className={classes.pagination}>
          {[...Array(pageCount)].map((_, i) => (
            <MaterialButton
              key={i}
              size="small"
              color={i + 1 === page ? 'primary' : undefined}
              onClick={() => gotoPage(i + 1)}
            >
              {i + 1}
            </MaterialButton>
          ))}
        </Paper>
      )}
    </>
  );
}
